<template>
  <div class="messages">
    <div class="top">
      <div class="title-box">
        消息
      </div>
      <div class="btn-box">
        <el-button
          type="primary"
          size="mini"
          @click="goback"
        >
          返回
        </el-button>
      </div>
    </div>
    <div class="content" @scroll="scroll_content" v-loading="loading" element-loading-text="消息加载中...">
      <el-collapse  @change="handleChange">
        <el-collapse-item v-for="(item,index) in listData" :key="index" :name="item.push_id">
          <template slot="title">
            <div class="item-title">
              <img v-show="!item.is_read" src="@/assets/img/news.png" alt="" />
              <img v-show="item.is_read" src="@/assets/img/nonews.png" alt="" />
              <span>{{item.title}}</span>
              <span>{{item.push_time}}</span>
            </div>
          </template>
          <div class="item-content">
            <div v-if="item.message_type == 1">
              <p class="tip" style="color:red;">告警</p>
              <p>告警类型:{{item.title}}</p>
              <p>机号: {{item.content.instrument_id}} &nbsp; {{item.content.position}}</p>
              <p>医院: {{item.content.hospital}}</p>
              <p>告警时间: {{item.content.warning_time}}</p>
            </div>
            <div v-if="item.message_type == 2">
              <p class="tip" style="color:#E6A23C;">{{item.title}}</p>
              <p>机号: {{item.content.instrument_id}} &nbsp; {{item.content.position}}</p>
              <p>医院: {{item.content.hospital}}</p>
              <p>下次保养时间: {{item.content.next_date}}</p>
            </div>
            <div v-if="item.message_type == 3">
              <p class="tip"  style="color: red;">{{item.content.rule}}:失控</p>
              <p class="result-box">结果: <span>{{item.content.result}}</span><span>{{item.content.level}}</span></p>
              <p>项目: {{item.content.testing}}</p>
              <p>机号: {{item.content.instrument_id}} &nbsp; {{item.content.position}}</p>
              <p>型号: {{item.content.platform}}</p>
              <p>医院: {{item.content.hospital}}</p>
            </div>
<!--            解绑信息   -->
            <div v-if="item.message_type == 4">
              <p>仪器序列号: {{item.content.instrument_id}}</p>
              <p>售后人员: {{item.content.engineer_name}}</p>
              <p>医院: {{item.content.hospital_full_name}}</p>
              <p>经销商: {{item.content.customer_name}}</p>
              <p style="color:#4095E5;cursor: pointer" @click="checkUnbound(item)">查看详情</p>
            </div>
            <div v-if="item.message_type == 5">
              <p>仪器序列号: {{item.content.instrument_id}}</p>
              <p>售后人员: {{item.content.user_name}}</p>
              <p>医院: {{item.content.hospital_name}}/{{item.content.hospital_label}}</p>
              <p>经销商: {{item.content.customer_name}}</p>
              <p style="color:#4095E5;cursor: pointer" @click="checkInstrument(item)">查看详情</p>
            </div>
          </div>
        
        </el-collapse-item>
      </el-collapse>
      <el-dialog
          title="查看解绑信息"
          class="bind_check"
          :visible.sync="dialogVisible"
          width="60%"
          :before-close="handleClose">
        <el-form :model="form" label-width="120px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="仪器序列号:">
                <span>{{form.instrument_id}}</span>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="解绑原因:">
                <span>{{form.unbind_reason ? handleReason(form.unbind_reason) : '/'}} <span v-if="form.unbind_reason === 3004">({{form.reasons}})</span> </span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="仪器流向:">
                <span>{{form.instrument_flow ? handleFlow(form.instrument_flow) : '/'}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="物流单号:" v-if="form.instrument_flow === 3010">
                <span>{{form.express_no ? form.express_no : '/'}}</span>
              </el-form-item>
              <el-form-item label="经销商:" v-if="form.instrument_flow === 3011">
                <span>{{form.customer_name ? form.customer_name : '/'}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row  v-if="form.instrument_flow === 3010">
            <el-col :span="24">
              <el-form-item label="接收人:">
                <span>{{form.receive_user_name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="联系电话:">
                <span>{{form.receive_phone ? handlePhone(form.receive_phone) : '/'}}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="抄送人员:">
                <span >{{form.cc_user_name ? form.cc_user_name.toString() : '/'}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="位置信息:">
                <span>{{form.location_info}}</span>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <span slot="footer" class="dialog-footer" style="text-align: center">
          <el-button size="mini" type="primary" v-if="form.instrument_flow  === 3010 && !form.is_unbind && userId == 234" @click="agreeUnbind()">同意解绑</el-button>
          <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from '@/api/message.js'
export default {
  data() {
    return {
      loading: false,
      userId:  JSON.parse(localStorage.getItem("userInfo")).user_id,
      pageSize: 20,
		  pageNum: 0,
      listData: [],
      hasNextPage: true,
      status: 'more',
      dialogVisible: false,
      form: {},
      directionOptions:[  //仪器位置
        {val:0, name: "院内报废", value: 3012},
        {val:1, name: "返回奥普", value: 3010},
        {val:2, name: "返回经销商", value: 3011},
      ],
      reasonOptions:[  //解绑 原因
        {val:0, name: "停用", value: 3001},
        {val:1, name: "报废", value: 3002},
        {val:2, name: "装机单信息填写错误", value: 3003},
        {val:3, name: "其他", value: 3004},
      ],
    }
  },
  mounted() {
    this.get_push_messages();

  },
  destroyed() {
    // let tempList = this.listData.filter(e => e.is_read == false)
		// if(tempList.length==0){
		// 	this.check_message_queueFun();
		// }else{
		// 	let pk_list = tempList.map(e => e.push_id)
		// 	api.change_isread({pk_list}).then(res => {
		// 		this.check_message_queueFun();
		// 	})
		// }
	},
  methods: {
    // 获取列表
		get_push_messages() {
      if (this.status === "loading") {
			  return false;
			}else {
        this.pageNum++;
      }
      this.loading = true
			this.status = "loading";
      const total = this.pageNum * this.pageSize;
      let param = {
				  page: this.pageNum,
				  size: this.pageSize,
				};
      api.push_messages(param).then(res => {
        if(res.code === 200) {
          let data = res.data.data;
          this.loading = false
          if (data.length > 0) {
					    this.status = "more";
					    this.listData = this.listData.concat(data);
					    this.hasNextPage = res.data.count > total;
					} else {
					  this.pageNum--;
					  if (this.pageNum < 1) {
					    this.pageNum = 1;
					  }
					  this.status = "noMore";
					}
					if (data.length < this.pageSize) {
					  this.status = "noMore";
					}
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    agreeUnbind(){
      this.$confirm('确定同意解绑吗？', '解绑提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          instrument: this.form.instrument_id,
          unbind_reason: this.form.unbind_reason,
          instrument_flow: this.form.instrument_flow,
          reasons: this.form.reasons,
          express_no: this.form.express_no,
          receive_user: this.form.receive_user_id,
          receive_phone: this.form.receive_phone,
        }
        api.agree_unbind(params).then(res=>{
          if(res.code === 200){
            this.$message.success('解绑成功')
            this.dialogVisible = false
          }
        }).finally(err=>{

        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });

    },
    handlePhone(val){
      let phoneNumber = val.toString()
      let firstPart = phoneNumber.slice(0, 3); // 捕获前三位
      let middlePart = '****'; // 替换的星号
      let lastPart = phoneNumber.slice(7);
      return  firstPart + middlePart + lastPart
    },
    // 返回上一页
    goback() {
      // this.$router.push({ path: '/dataBoard',})
      window.history.back()
    },
    handleChange(val){
      let preTarget = this.listData.find(item => item.push_id === val[val.length-1])
      let target = null
      if(preTarget && !preTarget.is_read && ![4,5].includes(preTarget.message_type) ){
        target = preTarget.push_id
        this.update_chang_isread(target,true)
      }
    },
    // 刷新已读消息状态
		update_chang_isread(pk,is_read){
			// api.change_isread({pk,is_read}).then(res => {
      let pk_list = [pk]
      this.listData.forEach(item => {
        if(item.push_id === pk){
          item.is_read = true
        }
      })
			api.change_isread({pk_list: pk_list}).then(res => {
        this.check_message_queueFun();
			})
		},
    // 消息推送星标
    check_message_queueFun(){
      api.check_message_queue().then(res=>{
				this.$store.commit("domessage_count", res.data.message_count);
			})
    },
    //触底刷新机制
    scroll_content(event) {
      let el=event.target;
    
      if(el.scrollTop+el.clientHeight+10>=el.scrollHeight){
        if (this.hasNextPage) {
          this.get_push_messages();
        } else {
          this.status = "noMore";
        }
        // this.get_push_messages();

      }
    },
    checkUnbound(item){
      this.update_chang_isread(item.push_id)
      this.dialogVisible = true;
      api.unbind_message({message_id : item.message}).then(res=>{
        this.form = res.data
      })
    },
    checkInstrument(item){
      this.update_chang_isread(item.push_id)
      this.$router.push({ path: '/instruments-detail', query: {id: item.content.instrument_id, type: 'msg'}})
    },
    handleClose(){
      this.dialogVisible = false;
    },
    handleReason(val){
      return this.reasonOptions.find(item=>item.value === val).name
    },
    handleFlow(val){
      return this.directionOptions.find(item=>item.value === val).name
    },
  }
}
</script>

<style lang="scss" scoped>
.messages {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url(../../assets/img/bg.png);
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: px(20) 5%;
    .title-box {
      font-weight: bold;
      color: #111111;
      font-size: $title-size;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
   
  }
  .content {
    width: 90%;
    margin-left: 5%;
    height: calc(100vh - px(120));
    overflow: auto;
  }
}
.item-title {
  padding-left: px(20);
  display: flex;
  align-items: center;
  font-size: px(16);
  img {
    width: px(30);
    height: px(30);
  }
  span {
    margin-left: px(10);
  }
}
.item-content {
  padding-left: px(20);
  .tip {
    font-size: px(18);
    font-weight: bold;
  }
  p {
    font-size: px(16);
  }
  .result-box {
    &> span {
      font-size: px(18);
      font-weight: bold;
    }
    span:nth-child(2) {
      margin-left: px(10);
    }

  }
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #eee;
}
::v-deep .el-dialog__footer{
  text-align: center;
}
::v-deep .el-form-item{
  margin-bottom:10px;
}
::v-deep .el-dialog__body{
  padding-left: 80px
}
</style>